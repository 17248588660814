<template>
  <v-list-group
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :color="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'black'"
  >
    <template v-slot:prependIcon>
      <v-icon small>{{item.icon}}</v-icon>
    </template>
    <template v-slot:appendIcon>
      <v-icon small>mdi-menu-down</v-icon>
    </template>
    <template v-slot:subGroupAppendIcon>
      <v-icon small>mdi-menu-down</v-icon>
    </template>
    <template v-slot:activator v-if="item.badge">
      <mex-badge v-if="item.badge" :content="item.badge.content" :value="item.badge.value" :color="item.badge.color">
        <v-list-item-icon v-if="text" class="v-list-item__icon--text" v-text="computedText" />

        <v-list-item-avatar v-else-if="item.avatar" class="align-self-center" color="grey">
          <v-img src="https://demos.creative-tim.com/material-dashboard-pro/assets/img/faces/avatar.jpg" />
        </v-list-item-avatar>

        <v-list-item-content class="mr-2">
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </mex-badge>
    </template>
    <template v-slot:activator v-else>
      <v-list-item-icon v-if="text" class="v-list-item__icon--text" v-text="computedText" />

      <v-list-item-avatar v-else-if="item.avatar" class="align-self-center" color="grey">
        <v-img src="https://demos.creative-tim.com/material-dashboard-pro/assets/img/faces/avatar.jpg" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group v-if="child.children" :key="`sub-group-${i}`" :item="child" />

      <base-item v-else class="ml-3" :key="`item-${i}`" :item="child" text @base-item-click="$emit('base-item-click', item)" />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from 'lodash/kebabCase';
import { mapState } from 'vuex';

export default {
  name: 'ItemGroup',
  components: {
    BaseItem: () => import('./Item.vue'),
    BaseItemSubGroup: () => import('./ItemSubGroup.vue'),
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeColor: 'red',
    };
  },
  computed: {
    ...mapState(['barColor']),
    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`,
      }));
    },
    computedText() {
      if (!this.item || !this.item.title) return '';

      let text = '';

      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    group() {
      return this.genGroup(this.item.children);
    },
  },
  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join('|');
    },
    setActiveColor() {
      this.activeColor = this.$vuetify.theme.dark ? 'white' : 'black';
    },
  },
  created() {
    this.setActiveColor();
  },
};
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}

.v-list .v-list-item__title {
  color: var(--v-activeColor);
}

.v-list .v-list-item--active .v-list-item__title {
  color: var(--v-activeColor);
}
</style>
